import React, { useState } from 'react';

import HomeButton from './HomeButton';

export default React.memo(function InvitePage() {
  const initialState = {
    about: '',
    email: '',
    comments: '',
    name: '',
  };
  const [state, setState] = useState(initialState);

  const submitForm = ev => {
    // This is actually a honeypot
    if (state.comments) {
      ev.preventDefault();
      console.log('Bot trying to submit form');
      window.location = 'https://www.reddit.com/r/FuckYou/';
      return false;
    }

    console.log('form submission success');
  };

  return (
    <div>
      <HomeButton />
      <main>
        <section>
          <h1>Join Us</h1>
        </section>
        <section>
          <h2>Sign up to be on the show!</h2>
          <form
            onSubmit={submitForm}
            action="https://formspree.io/tyler@buchea.com"
            method="POST"
          >
            <div
              style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}
            >
              <label htmlFor="comments">Comments</label>
              <input
                name="comments"
                type="text"
                onChange={ev =>
                  setState({ ...state, honeypot: ev.target.value })
                }
                value={state.honeypot}
              />
            </div>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              onChange={ev => setState({ ...state, email: ev.target.value })}
              value={state.email}
            />
            <label htmlFor="name">Name</label>
            <input
              name="name"
              type="text"
              onChange={ev => setState({ ...state, name: ev.target.value })}
              value={state.name}
            />
            <label htmlFor="about">Tell us about yourself</label>
            <textarea
              name="about"
              type="text"
              onChange={ev => setState({ ...state, about: ev.target.value })}
              value={state.about}
              rows="4"
            />
            <br />
            <input type="submit" className="button" value="SUBMIT" />
          </form>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>
            <a href="mailto:podcast@remoteguysnight.com">
              <i className="fa fa-envelope" /> podcast@remoteguysnight.com
            </a>
            <br />
            <br />
            <a href="https://twitter.com/remoteguysnight">
              <i className="fab fa-twitter" /> @remoteguysnight
            </a>
          </p>
        </section>
      </main>
    </div>
  );
});
