import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import InvitePage from '../components/InvitePage';

export default function Invite() {
  return (
    <Layout>
      <SEO title="Invite" />
      <InvitePage />
    </Layout>
  );
}
