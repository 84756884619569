import React from 'react';
import { Link } from 'gatsby';

export default function HomeButton() {
  return (
    <Link style={{ position: 'absolute', left: 20, top: 20 }} to="/">
      <i className="fa fa-chevron-left" />
    </Link>
  );
}
